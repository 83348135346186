import { axiosClassic } from '../api/api'

export const OrderService = {
  async create(productId, classificationId, dateId, ageId) {
    const res = await axiosClassic.post(
      `/order/${productId}?classificationId=${classificationId}&dateId=${dateId}&ageId=${ageId}`
    )

    return res.data
  },

  async get(orderId) {
    const res = await axiosClassic.get(`/order/${orderId}`)

    return res.data
  },

  async addPackage(productOrderId, body) {
    const res = await axiosClassic.patch(`/order/product-order/${productOrderId}/package`, body)

    return res.data
  },

  async addProgramDate(productOrderId, body) {
    const res = await axiosClassic.patch(`/order/product-order/${productOrderId}/program-date`, body)

    return res.data
  },

  async saveProductOrder(orderId) {
    const res = await axiosClassic.patch(`/order/${orderId}/product-order/save`)

    return res.data
  },

  async validateProductOrder(productOrderId) {
    const res = await axiosClassic.get(`/order/product-order/${productOrderId}/validate`)

    return res.data
  },

  async addTransferDetails(productOrderId, body) {
    const res = await axiosClassic.patch(`/order/product-order/${productOrderId}/transfer-details`, body)

    return res.data
  },

  async getLocationsByOrder(productOrderId) {
    const res = await axiosClassic.get(`/location/by-product-order/${productOrderId}`)

    return res.data
  },

  async createNewProductOrder(orderId) {
    const res = await axiosClassic.post(`/order/${orderId}/product-order`)

    return res.data
  },

  async createProductOrderBackup(productOrderId) {
    const res = await axiosClassic.post(`/product-order/${productOrderId}/backup`)

    return res.data
  },

  async discardProductOrder(productOrderId) {
    const res = await axiosClassic.patch(`/order/product-order/${productOrderId}/discard-changes`)

    return res.data
  },

  async deleteProductOrder(productOrderId) {
    const res = await axiosClassic.delete(`/order/product-order/${productOrderId}`)

    return res.data
  },

  async selectLocation(productOrderId, body) {
    const res = await axiosClassic.patch(`/order/product-order/${productOrderId}/location`, body)

    return res.data
  },

  async getAgesByOrder(productOrderId) {
    const res = await axiosClassic.get(`/age/by-product-order/${productOrderId}`)

    return res.data
  },

  async selectAge(productOrderId, body) {
    const res = await axiosClassic.patch(`/order/product-order/${productOrderId}/age`, body)

    return res.data
  },

  async getSubjectByOrder(productOrderId) {
    const res = await axiosClassic.get(`/subject/by-product-order/${productOrderId}`)

    return res.data
  },

  async selectSubject(productOrderId, body) {
    const res = await axiosClassic.patch(`/order/product-order/${productOrderId}/subject`, body)

    return res.data
  },

  async selectChildSubject(productOrderId, body) {
    const res = await axiosClassic.patch(`/order/product-order/${productOrderId}/child-order/subject`, body)

    return res.data
  },

  async selectPreferredMonths(productOrderId, body) {
    const res = await axiosClassic.patch(`/order/product-order/${productOrderId}/preferred-months`, body)

    return res.data
  },

  async selectPreferredChildMonths(productOrderId, body) {
    const res = await axiosClassic.patch(`/order/product-order/${productOrderId}/child-order/preferred-months`, body)

    return res.data
  },

  async getEducationalModelsByProductOrder(productOrderId) {
    const res = await axiosClassic.get(`/educational-model/by-product-order/${productOrderId}`)

    return res.data
  },

  async selectEducationalModelByProductOrder(productOrderId, body) {
    const res = await axiosClassic.patch(`/order/product-order/${productOrderId}/educational-model`, body)

    return res.data
  },

  async addBillingAddress(orderId, body) {
    const res = await axiosClassic.patch(`/order/${orderId}/billing-address`, body)

    return res.data
  },

  async payment(orderId, isDeposit) {
    try {
      const res = await axiosClassic.patch(`/order/${orderId}/payment?deposit=${isDeposit}`, {})

      return res.data
    } catch (error) {
      throw new Error(error)
    }
  },

  async getCompletedOrder(orderId) {
    try {
      const res = await axiosClassic.get(`/order/${orderId}/completed`)

      return res.data
    } catch (error) {
      throw new Error(error)
    }
  },

  async addMailConsent(orderId, body) {
    try {
      const res = await axiosClassic.patch(`/order/${orderId}/mail-consent`, body)

      return res.data
    } catch (error) {
      throw new Error(error)
    }
  },

  async abandonOrder(orderId, body) {
    try {
      const res = await axiosClassic.patch(`/order/${orderId}/abandon`)

      return res.data
    } catch (error) {
      throw new Error(error)
    }
  },

  async getProgramDatesByProductOrder(productOrderId) {
    const res = await axiosClassic.get(`/program-date/by-product-order/${productOrderId}`)

    return res.data
  },

  async getFlywirePaymentStatus(orderId) {
    const res = await axiosClassic.get(`/order/flywire-payment-status/${orderId}`)

    return res.data
  }
}
