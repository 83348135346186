import classNames from 'classnames'
import { useState } from 'react'
import { useController, useForm } from 'react-hook-form'

import { Box, IconButton, Typography } from '@mui/material'

import AlertGoldIcon from '../../assets/icons/AlertGoldIcon'
import CheckIcon from '../../assets/icons/CheckIcon'
import CrossMarkIcon from '../../assets/icons/CrossmarkIcon'
import InfoIcon from '../../assets/icons/InfoIcon'
import RefreshIcon from '../../assets/icons/RefreshIcon'
import SuccessGreenIcon from '../../assets/icons/SuccessGreenIcon'
import { addCoupon, removeCoupon } from '../../store/enrolment/enrolment.actions'
import { selectOrder } from '../../store/enrolment/enrolment.selectors'
import CustomTooltip from '../Tooltip'
import {
  StyledAlert,
  StyledCoupons,
  StyledDiscountBlock,
  StyledDiscountError,
  StyledDiscountForm,
  StyledDiscountTitle
} from './Discount.styled'

import { useDispatch, useSelector } from 'react-redux'

const getAllCoupons = order => {
  const coupons = order.productOrders.map(({ coupon }) => coupon).filter(item => item)

  if (order.coupon) {
    coupons.push(order.coupon)
  }

  const allCoupons = coupons.reduce((acc, currentValue) => {
    if (!acc.find(({ title }) => title === currentValue.title)) {
      acc.push(currentValue)
    }
    return acc
  }, [])

  return allCoupons
}

const Discount = ({ titleClassName, secondVersion = false, isAccordion }) => {
  const order = useSelector(selectOrder)

  const dispatch = useDispatch()

  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isDiscountInputVisible, setDiscountInputVisible] = useState(false)
  const [isVisibleApprove, setIsVisibleApprove] = useState(false)
  const [isLoadingRemoveCoupon, setIsLoadingRemoveCoupon] = useState(false)
  const [isLoadingAdd, setIsLoadingAdd] = useState(false)
  const [isVisibleAlert, setIsVisibleAlert] = useState(false)

  const coupons = getAllCoupons(order)

  const { handleSubmit, control } = useForm({ mode: 'onChange' })

  const { field: discount } = useController({
    name: 'coupon',
    control: control,
    rules: {
      required: { value: true }
    }
  })

  const onSubmit = () => {
    setIsVisibleApprove(true)
  }

  const submitCoupon = () => {
    setIsLoadingAdd(true)

    dispatch(
      addCoupon({
        orderId: order.id,
        body: { coupon: discount.value },
        callback: () => {
          setIsError(false)
          setDiscountInputVisible(false)
          setIsVisibleApprove(false)
          discount.onChange('')
          setIsLoadingAdd(false)
          setIsVisibleAlert(true)

          setTimeout(() => setIsVisibleAlert(false), 3000)
        },
        callbackRejected: () => {
          setIsError(true)
          setIsLoadingAdd(false)
        }
      })
    )
  }

  const removeError = () => {
    setIsError(false)
    setDiscountInputVisible(true)
    setIsVisibleApprove(false)
    discount.onChange('')
  }

  const removeCurrentCoupon = () => {
    setIsLoadingRemoveCoupon(true)
    dispatch(
      removeCoupon({
        orderId: order.id,
        callback: () => {
          setIsLoadingRemoveCoupon(false)
        }
      })
    )
  }

  return (
    <StyledDiscountBlock>
      {isError ? (
        <StyledDiscountError>
          <span>Wrong code</span>
          <button onClick={removeError}>
            <RefreshIcon /> Retry
          </button>
        </StyledDiscountError>
      ) : isDiscountInputVisible ? (
        <StyledDiscountForm
          control={control}
          onSubmit={handleSubmit(onSubmit)}
          className={classNames({
            discounted: isSuccess,
            'in-accordion': isAccordion
          })}
        >
          <input placeholder='Enter discount code' value={discount.value} onChange={discount.onChange} />
          <button type='submit'> {isSuccess ? <CheckIcon /> : 'Apply'}</button>

          {isVisibleApprove && (
            <Box className='approve'>
              <AlertGoldIcon />

              <Box>
                <Typography>
                  Please note that applying this discount code will override programme discounts in your cart. Are you
                  sure you want to apply this code?
                </Typography>

                <Box className='buttons'>
                  <button className='apply' onClick={submitCoupon}>
                    Apply code
                  </button>

                  <button
                    className='delete'
                    onClick={() => {
                      setDiscountInputVisible(false)
                      setIsVisibleApprove(false)
                      discount.onChange('')
                    }}
                  >
                    Delete code
                  </button>
                </Box>
              </Box>
            </Box>
          )}

          {(isLoadingAdd || isVisibleApprove) && <Box className='drawer'></Box>}
        </StyledDiscountForm>
      ) : (
        <>
          <StyledDiscountTitle className={titleClassName} onClick={() => setDiscountInputVisible(true)}>
            Have a discount code?
            {secondVersion && (
              <CustomTooltip
                title={
                  <>
                    <Typography className='tooltip-header' sx={{ color: '#484848' }} component='h3'>
                      Discount codes
                    </Typography>
                    <Typography className='tooltip-text'>
                      Some promotional codes cannot be used in conjunction with programme discounts.
                    </Typography>
                  </>
                }
                slotProps={{ tooltip: { sx: { maxWidth: '367px' } } }}
              >
                <IconButton sx={{ padding: 0 }}>
                  <InfoIcon />
                </IconButton>
              </CustomTooltip>
            )}
          </StyledDiscountTitle>
        </>
      )}

      {!!coupons.length && (
        <StyledCoupons>
          {coupons.map(coupon => (
            <Box
              key={coupon.id}
              className={classNames('coupon', {
                'coupon-gray': secondVersion
              })}
            >
              {coupon.title}
              <CrossMarkIcon onClick={removeCurrentCoupon} />

              {isLoadingRemoveCoupon && <Box className='drawer'></Box>}
            </Box>
          ))}
        </StyledCoupons>
      )}

      {isVisibleAlert && (
        <StyledAlert>
          <Box>
            <SuccessGreenIcon />
            Discount code applied
          </Box>
        </StyledAlert>
      )}
    </StyledDiscountBlock>
  )
}

export default Discount
