import { useCallback, useState } from 'react'

import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'

import BookIcon from '../../assets/icons/BookIcon'
import RefreshIcon from '../../assets/icons/RefreshIcon'
import TrashIcon from '../../assets/icons/TrashIcon'
import ChangeProgrammeModal from '../../components/ChangeProgrammeModal'
import RemoveModal from '../../components/RemoveModal'
import { transferOptions } from '../../constants/transferOptions'
import { createProductOrderBackup, deleteProductOrder } from '../../store/enrolment/enrolment.actions'
import {
  selectActiveProductOrderId,
  selectIsNewProgramAdded,
  selectOrder
} from '../../store/enrolment/enrolment.selectors'
import { setIsChangeModalOpen } from '../../store/enrolment/enrolment.slice'
import Discount from '../Discount'
import { SummaryStyled } from './Summary.styled'

import { useDispatch, useSelector } from 'react-redux'

const handleCreateTransferData = transferOptionPrice => {
  return [
    {
      label: 'Pick-up + Return',
      value: 'Pick-up + Return',
      transferFrom: true,
      transferTo: true,
      mostPopular: true,
      price: transferOptionPrice && transferOptionPrice * 2
    },
    {
      label: 'Pick-up only',
      value: 'Pick-up only',
      transferFrom: true,
      transferTo: false,
      mostPopular: false,
      price: transferOptionPrice
    },
    {
      label: 'Return only',
      value: 'Return only',
      transferFrom: false,
      transferTo: true,
      mostPopular: false,
      price: transferOptionPrice
    },
    {
      label: 'No (maybe later)',
      value: 'No (maybe later)',
      transferFrom: false,
      transferTo: false,
      mostPopular: false
    }
  ]
}

const Summary = () => {
  const dispatch = useDispatch()
  const order = useSelector(selectOrder)
  const isNewProgramAdded = useSelector(selectIsNewProgramAdded)
  const isSmallScreen = useMediaQuery('(max-width: 1024px)')

  const activeProductId = useSelector(selectActiveProductOrderId)

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenChangeModal, setIsOpenChangeModal] = useState(false)
  const [productOrderId, setProductOrderId] = useState(null)
  const [productIdForChange, setProductIdForChange] = useState(null)

  const handleDeleteProductOrder = () => {
    setIsOpenDeleteModal(false)
    if (productOrderId) {
      dispatch(
        deleteProductOrder({
          productOrderId
        })
      )
    }
  }

  const openRemoveModal = productOrder => {
    setIsOpenDeleteModal(true)
    setProductOrderId(productOrder.id)
  }

  const handleCreateBackup = productOrder => {
    dispatch(createProductOrderBackup({ productOrderId: productOrder.id }))
    dispatch(setIsChangeModalOpen(true))
    setIsOpenChangeModal(true)
    setProductIdForChange(productOrder.id)
  }

  const closeChangeModal = useCallback(() => {
    setIsOpenDeleteModal(false)
    setProductOrderId(null)
  }, [])

  const firstProductOrder = order?.productOrders[0]
  const productOrders = order?.productOrders.length > 1 ? order?.productOrders.slice(1) : null

  return (
    <SummaryStyled>
      <Box className='summary'>
        {!isSmallScreen && <Typography component='h3'>Summary</Typography>}

        {firstProductOrder && (
          <Box className='summary-products-box'>
            <Box className='summary-products-wrapper'>
              <div className='product-location' style={{ background: firstProductOrder?.location?.color || '#9ACAB3' }}>
                {firstProductOrder?.location?.title}
              </div>
              <Box className='product-details'>
                {firstProductOrder?.product?.featuredImage ? (
                  <img src={firstProductOrder?.product?.featuredImage} alt='Mock alt name' className='product-image' />
                ) : (
                  <Box className='product-icon'>{firstProductOrder?.subject?.title && <BookIcon />}</Box>
                )}
                <Typography className='product-course-name'>
                  {firstProductOrder?.subject?.title.replace(/&amp;/g, '&')}
                </Typography>
              </Box>
              <Box className='product-components'>
                {firstProductOrder?.package?.packageTitle && (
                  <Box className='product-components-item'>{firstProductOrder?.package?.packageTitle}</Box>
                )}
                {firstProductOrder?.age?.title ? (
                  <Box className='product-components-item'>{firstProductOrder?.age?.title}</Box>
                ) : (
                  <Box className='product-components-item'>{firstProductOrder?.product?.taxonomy?.age?.title}</Box>
                )}
              </Box>
              {firstProductOrder?.package && (
                <Box className='product-general-info'>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography>{firstProductOrder.package.classification.title}</Typography>
                    <Typography sx={{ marginLeft: '8px' }}>
                      £{new Intl.NumberFormat('en-IN').format(firstProductOrder.price.programFee)}
                    </Typography>
                  </Box>
                  {firstProductOrder?.location?.title === 'Online' ? (
                    <Box className='product-additional-info'>
                      {firstProductOrder?.package?.description && (
                        <Typography
                          sx={{
                            marginTop: '4px'
                          }}
                        >
                          {firstProductOrder?.package?.description}
                        </Typography>
                      )}
                      {firstProductOrder?.programDate?.title && (
                        <Typography>{firstProductOrder?.programDate?.title}</Typography>
                      )}
                    </Box>
                  ) : (
                    <Box className='product-additional-info'>
                      {firstProductOrder?.programDate?.title && (
                        <Typography
                          sx={{
                            marginTop: '4px'
                          }}
                        >
                          {firstProductOrder?.programDate?.title}
                        </Typography>
                      )}
                      {((firstProductOrder?.transferFrom !== false && firstProductOrder?.transferFrom !== null) ||
                        (firstProductOrder?.transferTo !== false && firstProductOrder?.transferTo !== null)) &&
                        firstProductOrder.location.title !== 'Online' &&
                        firstProductOrder?.package?.classification?.title?.toLowerCase() !== 'non - residential' && (
                          <Typography>
                            Airport transfer (
                            {
                              transferOptions.find(
                                option =>
                                  option.transferFrom === firstProductOrder.transferFrom &&
                                  option.transferTo === firstProductOrder.transferTo
                              )?.label
                            }
                            )
                          </Typography>
                        )}
                    </Box>
                  )}
                  <Box className='product-additional-info'>
                    {firstProductOrder?.preferredMonths && (
                      <Typography>
                        {firstProductOrder?.preferredMonths?.map(item => `${item.month} ${item.year}`).join(', ')}
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
              {firstProductOrder?.price && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <Box className='product-price-item'>
                    <Typography>Programme fee</Typography>
                    <Typography>
                      £{new Intl.NumberFormat('en-IN').format(firstProductOrder.price.programFee)}
                    </Typography>
                  </Box>
                  {!!firstProductOrder?.price?.programDiscount && (
                    <Box className='product-price-item'>
                      <Typography>Programme discount</Typography>
                      <Typography className='product-price-item-discount'>
                        -£
                        {new Intl.NumberFormat('en-IN').format(firstProductOrder?.price?.programDiscount)}
                      </Typography>
                    </Box>
                  )}
                  {!!firstProductOrder?.price?.couponDiscount && (
                    <Box className='product-price-item'>
                      <Typography>Discount: {firstProductOrder?.price?.couponDiscount?.title}</Typography>
                      <Typography className='product-price-item-discount'>
                        -£
                        {new Intl.NumberFormat('en-IN').format(firstProductOrder?.price?.couponDiscount?.amount)}
                      </Typography>
                    </Box>
                  )}
                  {((firstProductOrder?.transferFrom !== false && firstProductOrder?.transferFrom !== null) ||
                    (firstProductOrder?.transferTo !== false && firstProductOrder?.transferTo !== null)) &&
                    firstProductOrder.location.title !== 'Online' &&
                    firstProductOrder?.package?.classification?.title?.toLowerCase() !== 'non - residential' && (
                      <Box className='product-price-item'>
                        <Typography>
                          Airport transfer (
                          {
                            transferOptions.find(
                              option =>
                                option.transferFrom === firstProductOrder.transferFrom &&
                                option.transferTo === firstProductOrder.transferTo
                            )?.label
                          }
                          )
                        </Typography>
                        <Typography>
                          £
                          {
                            handleCreateTransferData(firstProductOrder?.location?.airportTransferPrice).find(
                              option =>
                                option.transferFrom === firstProductOrder.transferFrom &&
                                option.transferTo === firstProductOrder.transferTo
                            )?.price
                          }
                        </Typography>
                      </Box>
                    )}
                  <Box className='product-price-item product-price-item-total'>
                    <Typography>Subtotal</Typography>
                    <Typography>£{new Intl.NumberFormat('en-IN').format(firstProductOrder.price.subtotal)}</Typography>
                  </Box>
                </Box>
              )}
            </Box>
            {firstProductOrder?.childProductOrder && <Box className='summary-products-divider' />}
            <Box className='summary-products-wrapper'>
              {firstProductOrder?.childProductOrder && (
                <Box className='product-child-program'>
                  <div
                    className='product-child-location'
                    style={{ background: firstProductOrder?.childProductOrder?.location?.color || '#9ACAB3' }}
                  >
                    {firstProductOrder?.childProductOrder?.location?.title}
                  </div>
                  <Box className='product-details'>
                    {firstProductOrder?.childProductOrder?.subject?.title && (
                      <>
                        {firstProductOrder?.childProductOrder?.product?.featuredImage ? (
                          <img
                            src={firstProductOrder?.childProductOrder?.product?.featuredImage}
                            alt='Mock alt name'
                            className='product-image'
                          />
                        ) : (
                          <Box className='product-icon'>
                            <BookIcon />
                          </Box>
                        )}
                        <Typography className='product-course-name'>
                          {firstProductOrder?.childProductOrder?.subject?.title.replace(/&amp;/g, '&')}
                        </Typography>
                      </>
                    )}
                  </Box>

                  <Box className='product-components'>
                    {firstProductOrder?.childProductOrder?.educationalModel?.title && (
                      <Box className='product-components-item'>
                        {firstProductOrder?.childProductOrder?.educationalModel?.title}
                      </Box>
                    )}
                    <Box className='product-components-item'>{firstProductOrder?.childProductOrder?.age?.title}</Box>
                  </Box>
                  {firstProductOrder?.childProductOrder?.package && (
                    <Box className='product-general-info'>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: '4px'
                          }}
                        >
                          {firstProductOrder.childProductOrder?.package?.classification?.title}
                        </Typography>
                        <Typography sx={{ marginLeft: '8px' }}>
                          £
                          {new Intl.NumberFormat('en-IN').format(firstProductOrder.childProductOrder?.price.programFee)}
                        </Typography>
                      </Box>
                      <Box className='product-additional-info'>
                        {firstProductOrder?.childProductOrder?.package?.description && (
                          <Typography>{firstProductOrder?.childProductOrder?.package?.description}</Typography>
                        )}
                        {firstProductOrder?.childProductOrder?.preferredMonths && (
                          <Typography>
                            {firstProductOrder?.childProductOrder?.preferredMonths?.map(item => item.month).join(', ')}{' '}
                            (bespoke dates)
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                  {firstProductOrder?.childProductOrder?.package && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                      <Box className='product-price-item'>
                        <Typography>Programme fee</Typography>
                        <Typography>
                          £
                          {new Intl.NumberFormat('en-IN').format(
                            firstProductOrder?.childProductOrder?.price?.programFee
                          )}
                        </Typography>
                      </Box>
                      {!!firstProductOrder?.childProductOrder?.price?.programDiscount && (
                        <Box className='product-price-item'>
                          <Typography>Programme discount</Typography>
                          <Typography className='product-price-item-discount'>
                            -£
                            {new Intl.NumberFormat('en-IN').format(
                              firstProductOrder?.childProductOrder?.price?.programDiscount
                            )}
                          </Typography>
                        </Box>
                      )}

                      {!!firstProductOrder?.childProductOrder?.price?.couponDiscount && (
                        <Box className='product-price-item'>
                          <Typography>
                            Discount: {firstProductOrder?.childProductOrder?.price?.couponDiscount?.title}
                          </Typography>
                          <Typography className='product-price-item-discount'>
                            -£
                            {new Intl.NumberFormat('en-IN').format(
                              firstProductOrder?.childProductOrder?.price?.couponDiscount?.amount
                            )}
                          </Typography>
                        </Box>
                      )}
                      <Box className='product-price-item product-price-item-total'>
                        <Typography>Subtotal</Typography>
                        <Typography>
                          £
                          {new Intl.NumberFormat('en-IN').format(firstProductOrder?.childProductOrder?.price?.subtotal)}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              {isSmallScreen && <Box className='summary-products-divider' />}
              <Box className='product-change' onClick={() => handleCreateBackup(firstProductOrder)}>
                <RefreshIcon />
                <Typography className='product-change-text'>Change</Typography>
              </Box>
            </Box>
          </Box>
        )}
        {productOrders &&
          productOrders?.map(productOrder => {
            if (productOrder.id !== isNewProgramAdded) {
              return (
                <Box key={productOrder.id} className='summary-products-box'>
                  <Box className='summary-products-wrapper'>
                    <div
                      className='product-location'
                      style={{ background: productOrder?.location?.color || '#9ACAB3' }}
                    >
                      {productOrder?.location?.title}
                    </div>
                    <Box className='product-details'>
                      {productOrder?.product?.featuredImage ? (
                        <img src={productOrder?.product?.featuredImage} alt='Mock alt name' className='product-image' />
                      ) : (
                        <Box className='product-icon'>{productOrder?.subject?.title && <BookIcon />}</Box>
                      )}
                      <Typography className='product-course-name'>
                        {productOrder?.subject?.title.replace(/&amp;/g, '&')}
                      </Typography>

                      {/* {activeProductId !== productOrder.id && ( */}
                      <IconButton onClick={() => openRemoveModal(productOrder)} className='product-delete-button'>
                        <TrashIcon />
                      </IconButton>
                      {/* )} */}
                    </Box>
                    <Box className='product-components'>
                      {productOrder?.package?.packageTitle && (
                        <Box className='product-components-item'>{productOrder?.package?.packageTitle}</Box>
                      )}
                      {productOrder?.age?.title ? (
                        <Box className='product-components-item'>{productOrder?.age?.title}</Box>
                      ) : (
                        <Box className='product-components-item'>{productOrder?.product?.taxonomy?.age?.title}</Box>
                      )}
                    </Box>
                    {productOrder?.package && (
                      <Box className='product-general-info'>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <Typography>{productOrder.package.classification.title}</Typography>
                          <Typography sx={{ marginLeft: '8px' }}>
                            £{new Intl.NumberFormat('en-IN').format(productOrder.price.programFee)}
                          </Typography>
                        </Box>
                        {productOrder?.location?.title === 'Online' ? (
                          <Box className='product-additional-info'>
                            {productOrder?.package?.description && (
                              <Typography
                                sx={{
                                  marginTop: '4px'
                                }}
                              >
                                {productOrder?.package?.description}
                              </Typography>
                            )}
                            {productOrder?.programDate?.title && (
                              <Typography>{productOrder?.programDate?.title}</Typography>
                            )}
                          </Box>
                        ) : (
                          <Box className='product-additional-info'>
                            {productOrder?.programDate?.title && (
                              <Typography
                                sx={{
                                  marginTop: '4px'
                                }}
                              >
                                {productOrder?.programDate?.title}
                              </Typography>
                            )}
                            {((productOrder?.transferFrom !== false && productOrder?.transferFrom !== null) ||
                              (productOrder?.transferTo !== false && productOrder?.transferTo !== null)) &&
                              productOrder.location.title !== 'Online' &&
                              productOrder?.package?.classification?.title?.toLowerCase() !== 'non - residential' && (
                                <Typography>
                                  Airport transfer (
                                  {
                                    transferOptions.find(
                                      option =>
                                        option.transferFrom === productOrder.transferFrom &&
                                        option.transferTo === productOrder.transferTo
                                    )?.label
                                  }
                                  )
                                </Typography>
                              )}
                          </Box>
                        )}
                        <Box className='product-additional-info'>
                          {productOrder?.preferredMonths && (
                            <Typography>
                              {productOrder?.preferredMonths?.map(item => `${item.month} ${item.year}`).join(', ')}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    )}
                    {productOrder?.price && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <Box className='product-price-item'>
                          <Typography>Programme fee</Typography>
                          <Typography>
                            £{new Intl.NumberFormat('en-IN').format(productOrder.price.programFee)}
                          </Typography>
                        </Box>

                        {!!productOrder?.price?.programDiscount && (
                          <Box className='product-price-item'>
                            <Typography>Programme discount</Typography>
                            <Typography className='product-price-item-discount'>
                              -£
                              {new Intl.NumberFormat('en-IN').format(productOrder?.price?.programDiscount)}
                            </Typography>
                          </Box>
                        )}

                        {!!productOrder?.price?.couponDiscount && (
                          <Box className='product-price-item'>
                            <Typography>Discount: {productOrder?.price?.couponDiscount?.title}</Typography>
                            <Typography className='product-price-item-discount'>
                              -£
                              {new Intl.NumberFormat('en-IN').format(productOrder?.price?.couponDiscount?.amount)}
                            </Typography>
                          </Box>
                        )}

                        {((productOrder?.transferFrom !== false && productOrder?.transferFrom !== null) ||
                          (productOrder?.transferTo !== false && productOrder?.transferTo !== null)) &&
                          productOrder.location.title !== 'Online' &&
                          productOrder?.package?.classification?.title?.toLowerCase() !== 'non - residential' && (
                            <Box className='product-price-item'>
                              <Typography>
                                Airport transfer (
                                {
                                  transferOptions.find(
                                    option =>
                                      option.transferFrom === productOrder.transferFrom &&
                                      option.transferTo === productOrder.transferTo
                                  )?.label
                                }
                                )
                              </Typography>
                              <Typography>
                                £
                                {
                                  handleCreateTransferData(productOrder?.location?.airportTransferPrice).find(
                                    option =>
                                      option.transferFrom === productOrder.transferFrom &&
                                      option.transferTo === productOrder.transferTo
                                  )?.price
                                }
                              </Typography>
                            </Box>
                          )}
                        <Box className='product-price-item product-price-item-total'>
                          <Typography>Subtotal</Typography>
                          <Typography>£{new Intl.NumberFormat('en-IN').format(productOrder.price.subtotal)}</Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {productOrder?.childProductOrder && <Box className='summary-products-divider' />}
                  <Box className='summary-products-wrapper'>
                    {productOrder?.childProductOrder && (
                      <Box className='product-child-program'>
                        <div className='product-child-location' style={{ background: '#000' }}>
                          {productOrder?.childProductOrder?.location?.title}
                        </div>
                        <Box className='product-details'>
                          {productOrder?.childProductOrder?.product?.featuredImage ? (
                            <img
                              src={productOrder?.childProductOrder?.product?.featuredImage}
                              alt='Mock alt name'
                              className='product-image'
                            />
                          ) : (
                            <Box className='product-icon'>
                              <BookIcon />
                            </Box>
                          )}
                          <Typography className='product-course-name'>
                            {productOrder?.childProductOrder?.subject?.title.replace(/&amp;/g, '&')}
                          </Typography>
                        </Box>
                        <Box className='product-components'>
                          {productOrder?.childProductOrder?.educationalModel?.title && (
                            <Box className='product-components-item'>
                              {productOrder?.childProductOrder?.educationalModel?.title}
                            </Box>
                          )}
                          <Box className='product-components-item'>{productOrder?.childProductOrder?.age?.title}</Box>
                        </Box>
                        {productOrder?.childProductOrder?.package && (
                          <Box className='product-general-info'>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Typography
                                sx={{
                                  marginTop: '4px'
                                }}
                              >
                                {productOrder.childProductOrder?.package?.classification?.title}
                              </Typography>
                              <Typography sx={{ marginLeft: '8px' }}>
                                £
                                {new Intl.NumberFormat('en-IN').format(
                                  productOrder.childProductOrder?.price.programFee
                                )}
                              </Typography>
                            </Box>
                            <Box className='product-additional-info'>
                              {productOrder?.product?.dateDuration && (
                                <Typography>{productOrder?.product?.dateDuration}</Typography>
                              )}
                              {productOrder?.childProductOrder?.preferredMonths && (
                                <Typography>
                                  {productOrder?.childProductOrder?.preferredMonths?.map(item => item.month).join(', ')}{' '}
                                  (bespoke dates)
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        )}
                        {productOrder?.childProductOrder?.package && (
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            <Box className='product-price-item'>
                              <Typography>Programme fee</Typography>
                              <Typography>
                                £
                                {new Intl.NumberFormat('en-IN').format(
                                  productOrder?.childProductOrder?.price?.programFee
                                )}
                              </Typography>
                            </Box>
                            <Box className='product-price-item'>
                              <Typography>Programme discount</Typography>
                              <Typography className='product-price-item-discount'>
                                -£
                                {new Intl.NumberFormat('en-IN').format(
                                  productOrder?.childProductOrder?.price?.programDiscount
                                )}
                              </Typography>
                            </Box>
                            <Box className='product-price-item product-price-item-total'>
                              <Typography>Subtotal</Typography>
                              <Typography>
                                £
                                {new Intl.NumberFormat('en-IN').format(
                                  productOrder?.childProductOrder?.price?.subtotal
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                    {isSmallScreen && <Box className='summary-products-divider' />}
                    <Box className='product-change' onClick={() => handleCreateBackup(productOrder)}>
                      <RefreshIcon />
                      <Typography className='product-change-text'>Change</Typography>
                    </Box>
                  </Box>
                </Box>
              )
            }
            return null
          })}

        {!isSmallScreen && <Discount titleClassName='bordered-discount' secondVersion />}
        {/* <StyledDiscount>
          <Discount />
          <CustomTooltip
            title={
              <>
                <Typography className='tooltip-header' sx={{ color: '#484848' }} component='h3'>
                  Discount codes
                </Typography>
                <Typography className='tooltip-text'>
                  Some promotional codes cannot be used in conjunction with programme discounts.
                </Typography>
              </>
            }
            slotProps={{ tooltip: { sx: { maxWidth: '367px' } } }}
          >
            <IconButton sx={{ padding: 0 }}>
              <InfoIcon />
            </IconButton>
          </CustomTooltip>
        </StyledDiscount> */}
      </Box>

      <RemoveModal open={isOpenDeleteModal} onClose={closeChangeModal} onDelete={() => handleDeleteProductOrder()} />

      {productIdForChange && (
        <ChangeProgrammeModal
          open={isOpenChangeModal}
          onClose={() => {
            setIsOpenChangeModal(false)
            setProductIdForChange(null)
            dispatch(setIsChangeModalOpen(false))
          }}
          productOrderId={productIdForChange}
        />
      )}
    </SummaryStyled>
  )
}

export default Summary
