import toast from 'react-hot-toast'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { CouponService } from '../../services/coupon'
import { OrderService } from '../../services/order'
import { StudentService } from '../../services/student'

export const createOrder = createAsyncThunk(
  'enrolment/createOrder',
  async ({ productId, classificationId, ageId, dateId, callback, callbackRejected }, { rejectWithValue }) => {
    try {
      const data = await OrderService.create(productId, classificationId, dateId, ageId)

      callback && callback()

      return data
    } catch (error) {
      callbackRejected && callbackRejected()

      return rejectWithValue('Failed to create order')
    }
  }
)

export const getOrder = createAsyncThunk(
  'enrolment/getOrder',
  async ({ orderId, callback, callbackRejected }, { rejectWithValue }) => {
    try {
      const data = await OrderService.get(orderId)

      callback && callback()

      return data
    } catch (error) {
      callbackRejected && callbackRejected()
      return rejectWithValue('Failed to fetch order')
    }
  }
)

export const addPackage = createAsyncThunk(
  'enrolment/addPackage',
  async ({ body, productOrderId, callback, callbackRejected }, { rejectWithValue }) => {
    try {
      const data = await OrderService.addPackage(productOrderId, body)
      callback && callback()

      return data
    } catch (error) {
      callbackRejected && callbackRejected()
      toast.error(error.response.data.message)

      return rejectWithValue('Failed to update package')
    }
  }
)

export const addProgramDate = createAsyncThunk(
  'enrolment/addProgramDate',
  async ({ body, productOrderId, callback, callbackRejected }, { rejectWithValue }) => {
    try {
      const data = await OrderService.addProgramDate(productOrderId, body)

      callback && callback()

      return data
    } catch (error) {
      callbackRejected && callbackRejected()
      toast.error(error.response.data.message)

      return rejectWithValue('Failed to update program date')
    }
  }
)

export const addTransferDetails = createAsyncThunk(
  'enrolment/addTransferDetails',
  async ({ body, productOrderId, callback, callbackRejected }, { rejectWithValue }) => {
    try {
      const data = await OrderService.addTransferDetails(productOrderId, body)

      callback && callback()

      return data
    } catch (error) {
      callbackRejected && callbackRejected()
      toast.error(error.response.data.message)

      return rejectWithValue('Failed to update transfer details')
    }
  }
)

export const saveProductOrder = createAsyncThunk(
  'enrolment/saveProductOrder',
  async ({ body, orderId, callback, callbackRejected }, { rejectWithValue }) => {
    try {
      const data = await OrderService.saveProductOrder(orderId)

      callback && callback()

      return data
    } catch (error) {
      callbackRejected && callbackRejected()

      return rejectWithValue('Failed to save product order')
    }
  }
)

export const validateProductOrder = createAsyncThunk(
  'enrolment/validateProductOrder',
  async ({ productOrderId, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.validateProductOrder(productOrderId)

      callback && callback()

      return data
    } catch (error) {
      return rejectWithValue('Failed to validate Product Order')
    }
  }
)

export const addPersonalDetails = createAsyncThunk(
  'enrolment/addPersonalDetails',
  async ({ body, orderId, callback, callbackRejected }, { rejectWithValue }) => {
    try {
      const data = await StudentService.addPersonalDetails(orderId, body)

      callback && callback()

      return data.studentDetails
    } catch (error) {
      callbackRejected && callbackRejected(error.response.data.message)

      return rejectWithValue('Failed to update student details')
    }
  }
)

export const addAcademicDetails = createAsyncThunk(
  'enrolment/addAcademicDetails',
  async ({ body, orderId, callback, callbackRejected }, { rejectWithValue }) => {
    try {
      const data = await StudentService.addAcademicDetails(orderId, body)

      callback && callback()

      return data.studentDetails
    } catch (error) {
      callbackRejected && callbackRejected()

      return rejectWithValue('Failed to update student details')
    }
  }
)

export const getLocationsByOrder = createAsyncThunk(
  'enrolment/getLocationsByOrder',
  async ({ productOrderId, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.getLocationsByOrder(productOrderId)

      callback && callback()

      return data
    } catch (error) {
      return rejectWithValue('Failed to fetch locations by order')
    }
  }
)

export const createNewProductOrder = createAsyncThunk(
  'enrolment/createNewProductOrder',
  async ({ orderId, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.createNewProductOrder(orderId)

      callback && callback()

      return data
    } catch (error) {
      return rejectWithValue('Failed to create New Product')
    }
  }
)

export const createProductOrderBackup = createAsyncThunk(
  'enrolment/createProductOrderBackup',
  async ({ productOrderId, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.createProductOrderBackup(productOrderId)

      callback && callback()

      return data
    } catch (error) {
      return rejectWithValue('Failed to create New Product')
    }
  }
)

export const discardProductOrder = createAsyncThunk(
  'enrolment/discardProductOrder',
  async ({ productOrderId, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.discardProductOrder(productOrderId)

      callback && callback()

      return data
    } catch (error) {
      return rejectWithValue('Failed to create New Product')
    }
  }
)

export const deleteProductOrder = createAsyncThunk(
  'enrolment/deleteProductOrder',
  async ({ productOrderId, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.deleteProductOrder(productOrderId)

      callback && callback()

      return data
    } catch (error) {
      return rejectWithValue('Failed to create New Product')
    }
  }
)

export const selectLocation = createAsyncThunk(
  'enrolment/selectLocation',
  async ({ productOrderId, body, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.selectLocation(productOrderId, body)

      callback && callback()

      return data
    } catch (error) {
      toast.error(error.response.data.message)
      return rejectWithValue('Failed to patch location')
    }
  }
)

export const getAgesByOrder = createAsyncThunk(
  'enrolment/getAgesByOrder',
  async ({ productOrderId, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.getAgesByOrder(productOrderId)

      callback && callback()

      return data
    } catch (error) {
      return rejectWithValue('Failed to fetch ages by order')
    }
  }
)

export const selectAge = createAsyncThunk(
  'enrolment/selectAge',
  async ({ productOrderId, body, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.selectAge(productOrderId, body)

      callback && callback()

      return data
    } catch (error) {
      toast.error(error.response.data.message)
      return rejectWithValue('Failed to patch age')
    }
  }
)

export const getSubjectsByOrder = createAsyncThunk(
  'enrolment/getSubjectsByOrder',
  async ({ productOrderId, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.getSubjectByOrder(productOrderId)

      callback && callback()

      return data
    } catch (error) {
      return rejectWithValue('Failed to fetch subjects by order')
    }
  }
)

export const selectSubject = createAsyncThunk(
  'enrolment/selectSubject',
  async ({ productOrderId, body, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.selectSubject(productOrderId, body)

      callback && callback()

      return data
    } catch (error) {
      toast.error(error.response.data.message)
      return rejectWithValue('Failed to patch subject')
    }
  }
)

export const selectChildSubject = createAsyncThunk(
  'enrolment/selectChildSubject',
  async ({ productOrderId, body, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.selectChildSubject(productOrderId, body)

      callback && callback()

      return data
    } catch (error) {
      toast.error(error.response.data.message)
      return rejectWithValue('Failed to patch child subject')
    }
  }
)

export const selectPreferredMonths = createAsyncThunk(
  'enrolment/selectPreferredMonths',
  async ({ productOrderId, body, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.selectPreferredMonths(productOrderId, body)

      callback && callback()

      return data
    } catch (error) {
      toast.error(error.response.data.message)
      return rejectWithValue('Failed to patch Preferred Months')
    }
  }
)

export const selectPreferredChildMonths = createAsyncThunk(
  'enrolment/selectPreferredChildMonths',
  async ({ productOrderId, body, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.selectPreferredChildMonths(productOrderId, body)

      callback && callback()

      return data
    } catch (error) {
      toast.error(error.response.data.message)
      return rejectWithValue('Failed to patch Preferred Child Months')
    }
  }
)

export const getEducationalModelsByProductOrder = createAsyncThunk(
  'enrolment/getEducationalModelsByProductOrder',
  async ({ productOrderId, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.getEducationalModelsByProductOrder(productOrderId)

      callback && callback()

      return data
    } catch (error) {
      return rejectWithValue('Failed to fetch educational models by product order')
    }
  }
)

export const selectEducationalModel = createAsyncThunk(
  'enrolment/selectEducationalModel',
  async ({ productOrderId, body, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.selectEducationalModelByProductOrder(productOrderId, body)

      callback && callback()

      return data
    } catch (error) {
      toast.error(error.response.data.message)
      return rejectWithValue('Failed to patch Educational Model')
    }
  }
)

export const addParentGuardianDetails = createAsyncThunk(
  'enrolment/addParentGuardianDetails',
  async ({ body, orderId, callback, callbackRejected }, { rejectWithValue }) => {
    try {
      const data = await StudentService.addParentGuardianDetails(orderId, body)

      callback && callback()

      return data.studentDetails
    } catch (error) {
      callbackRejected && callbackRejected()

      return rejectWithValue('Failed to update student details')
    }
  }
)

export const addCoupon = createAsyncThunk(
  'enrolment/addCoupon',
  async ({ body, orderId, callback, callbackRejected }, { rejectWithValue }) => {
    try {
      const data = await CouponService.addCoupon(orderId, body)

      callback && callback()

      return data
    } catch (error) {
      if (error.response.data.statusCode === 403) {
        toast.error("It isn't possible to add a coupon after payment has started")
      }
      callbackRejected && callbackRejected()

      return rejectWithValue('Failed to update student details')
    }
  }
)

export const removeCoupon = createAsyncThunk(
  'enrolment/removeCoupon',
  async ({ orderId, callback, callbackRejected }, { rejectWithValue }) => {
    try {
      const data = await CouponService.removeCoupon(orderId)

      callback && callback()

      return data
    } catch (error) {
      callbackRejected && callbackRejected()

      if (error.response.data.statusCode === 403) {
        toast.error("It isn't possible to remove a coupon after payment has started")
      }

      return rejectWithValue('Failed to update student details')
    }
  }
)

export const addBillingAddress = createAsyncThunk(
  'enrolment/addBillingAddress',
  async ({ orderId, body, callback, callbackRejected }, { rejectWithValue, getState }) => {
    try {
      const isDeposit = getState().enrolment.isDeposit

      const data = await OrderService.addBillingAddress(orderId, body)

      const paymentData = await OrderService.payment(orderId, isDeposit)

      callback && callback(paymentData)

      return data
    } catch (error) {
      if (error.response.data.statusCode === 403) {
        const isDeposit = getState().enrolment.isDeposit
        const paymentData = await OrderService.payment(orderId, isDeposit)

        callback && callback(paymentData)
      } else {
        callbackRejected && callbackRejected(error.response.data.message)
      }

      return rejectWithValue('Failed to patch Billing Address')
    }
  }
)

export const addMailConsent = createAsyncThunk(
  'enrolment/addMailConsent',
  async ({ orderId, body, callback, callbackRejected }, { rejectWithValue }) => {
    try {
      const data = await OrderService.addMailConsent(orderId, body)

      callback && callback()

      return data
    } catch (error) {
      callbackRejected && callbackRejected()

      return rejectWithValue('Failed to add Mail Consent')
    }
  }
)

export const abandonOrder = createAsyncThunk(
  'enrolment/abandonOrder',
  async ({ orderId, callback, callbackRejected }, { rejectWithValue }) => {
    try {
      const data = await OrderService.abandonOrder(orderId)

      callback && callback()

      return data
    } catch (error) {
      callbackRejected && callbackRejected()

      return rejectWithValue('Failed to abandon Order')
    }
  }
)

export const getProgramDatesByProductOrder = createAsyncThunk(
  'enrolment/getProgramDatesByProductOrder',
  async ({ productOrderId, callback }, { rejectWithValue }) => {
    try {
      const data = await OrderService.getProgramDatesByProductOrder(productOrderId)

      callback && callback()

      return data
    } catch (error) {
      return rejectWithValue('Failed to fetch get Program Dates By Product Order')
    }
  }
)
