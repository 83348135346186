import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Box, Link, Typography, useMediaQuery } from '@mui/material'

import EmailIcon from '../../assets/icons/EmailIcon'
import FormIcon from '../../assets/icons/FormIcon'
import InfoIcon from '../../assets/icons/InfoIcon'
import Logo from '../../assets/icons/Logo'
import SuccessImage from '../../assets/images/success-page.png'
import CustomButton from '../../components/Button'
import { OrderService } from '../../services/order'
import { Wrapper } from './styled'

const getDateFormPreferredArray = dateArray => {
  return dateArray.map(({ year, month }) => `${month} ${year}`).join(', ')
}

const handlePurchaseEvent = (order, paymentProvider) => {
  const allProducts = []

  order.productOrders.forEach(product => {
    allProducts.push(product)

    if (product.childProductOrder) {
      allProducts.push(product.childProductOrder)
    }
  })

  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      currency: 'GBP',
      payment_type: order.amountPaid < order.totalOrderAmount ? 'deposit CB' : 'total CB',
      payment_provider: paymentProvider === 'flywire' ? 'flywire' : 'stripe',
      transaction_id: `${order.paymentInformation.invoiceNumber}`,
      value: order.amountPaid,
      tax: '',
      shipping: '',
      shipping_tier: '',
      coupon: order.productOrders[0].coupon?.title || '',

      items: allProducts.map(product => {
        return {
          item_name: product.summary,
          item_id: product.product.productId,
          price: product.price.programFee,
          item_brand: product.package.packageTitle,
          item_category: product.location.title,
          item_variant: product.programDate
            ? product.programDate.title
            : product.preferredMonths
              ? getDateFormPreferredArray(product.preferredMonths)
              : '',
          quantity: 1
        }
      })
    }
  })
}

const handleBuyapowa = order => {
  var buyapowa = new window.Buyapowa({ url: 'https://bp.immerse.education', market: 'immerseeducation' })

  if (buyapowa) {
    const trackObj = {
      customer_name: `${order.studentDetails.firstName} ${order.studentDetails.lastName}`,
      customer_email: order.studentDetails.email,
      order_number: order.paymentInformation.invoiceNumber,
      order_value: order.amountPaid,
      voucher_code: order.productOrders[0].coupon?.title || ''
    }

    buyapowa.track(trackObj)
  }
}

const SuccessScreen = () => {
  const [order, setOrder] = useState(null)
  const [params] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState('')

  const orderId = params.get('orderId')
  const paymentType = params.get('paymentType')

  const isSmallScreen = useMediaQuery('(max-width: 1024px)')

  const isDeposit = order && order.amountPaid < order.totalOrderAmount

  useEffect(() => {
    setIsLoading(true)
    localStorage.removeItem('orderId')
    localStorage.removeItem('productId')
    localStorage.removeItem('classificationId')
    const fetchOrder = async () => {
      let flywirePaymentStatus

      if (paymentType === 'flywire') {
        try {
          const flywireStatus = await OrderService.getFlywirePaymentStatus(orderId)
          flywirePaymentStatus = flywireStatus

          setStatus(flywireStatus)
        } catch (error) {}
      }

      if (!flywirePaymentStatus || flywirePaymentStatus === 'initiated') {
        try {
          const data = await OrderService.getCompletedOrder(orderId)

          setIsLoading(false)
          setOrder(data)

          handlePurchaseEvent(data, paymentType)

          handleBuyapowa(data)
        } catch (error) {
          const interval = setInterval(async () => {
            try {
              const data = await OrderService.getCompletedOrder(orderId)

              setIsLoading(false)

              clearInterval(interval)
              setOrder(data)

              handleBuyapowa(data)
              handlePurchaseEvent(data, paymentType)
            } catch (error) {}
          }, 2000)
        }
      }
    }

    fetchOrder()
  }, [])

  return (
    <Wrapper>
      {(!status || status === 'initiated') && (
        <div className='message'>
          {isSmallScreen && (
            <Box className='image'>
              <Logo sx={{ width: '140px', height: '40px' }} fill='#fff' />
            </Box>
          )}
          <Typography component='h1'>Thank You</Typography>

          <Typography className='description'>
            {isLoading ? 'Your payment is being processed' : 'Your payment has successfully processed'}
          </Typography>

          {!isLoading && (
            <>
              <Typography>
                Order number: <Typography component='span'>{order?.paymentInformation?.invoiceNumber}</Typography>
              </Typography>
              <Typography>
                Payment Amount:{' '}
                <Typography component='span'>
                  £{order?.amountPaid ? new Intl.NumberFormat('en-IN').format(order?.amountPaid) : ''}
                </Typography>
              </Typography>

              {order?.generatedSiblingCoupon && (
                <>
                  <Typography>
                    Sibling Discount: <Typography component='span'>{order?.generatedSiblingCoupon?.title}</Typography>
                  </Typography>
                  {order?.generatedSiblingCoupon?.expiryDate && (
                    <Typography className='expiry-date'>
                      (discount code expires {format(new Date(order?.generatedSiblingCoupon?.expiryDate), 'dd/MM/y')})
                    </Typography>
                  )}
                </>
              )}
            </>
          )}

          <Typography
            sx={{ marginBottom: 0, paddingBottom: isSmallScreen ? '16px' : '32px', borderBottom: '1px solid #dadada' }}
          >
            Questions? Contact <Link href='mailto:support@immerse.education'>support@immerse.education</Link>
          </Typography>

          <Typography
            sx={{ marginBottom: 0, padding: isSmallScreen ? '16px 0' : '29px 0', borderBottom: '1px solid #dadada' }}
          >
            You have successfully enrolled in a programme with Immerse and have begun your education pathway.
          </Typography>

          <Typography component='h3'>We will contact you within 48 hours with the following:</Typography>

          <ul>
            <li>
              <EmailIcon />

              <Typography>Enrolment confirmation email</Typography>
            </li>
            <li>
              <InfoIcon />

              <Typography>Detailed information to help you prepare for your chosen programme</Typography>
            </li>

            <li>
              <FormIcon />

              <Typography>Forms to complete so that your tutor can tailor your academic experience</Typography>
            </li>
          </ul>

          <Link
            href='https://www.immerse.education'
            target='_blank'
            className='link'
            sx={{ textAlign: isSmallScreen && 'center' }}
          >
            <CustomButton sx={{ width: '100%' }}>Visit the Website</CustomButton>
          </Link>
        </div>
      )}

      {status === 'cancelled' && (
        <div className='message'>
          <Box className='error-box'>
            <Typography component='h1'>Payment Cancelled</Typography>

            <Typography>
              Still wish to enrol on a programme? Click the button below and start your educational pathway today.
            </Typography>

            <CustomButton
              onClick={() => {
                window.location.href = 'https://www.immerse.education/select-programme/'
              }}
              sx={{ marginTop: '16px' }}
            >
              Find your programme
            </CustomButton>
          </Box>
        </div>
      )}

      {status === 'failed' && (
        <div className='message'>
          <Box className='error-box'>
            <Typography component='h1'>Payment Failed</Typography>

            <Typography>
              Unfortunately it looks like an error has occurred. Please know, you have not been charged for this order.
              If you still wish to enrol on an Immerse programme, please click the button below.
            </Typography>

            <CustomButton
              onClick={() => {
                window.location.href = 'https://www.immerse.education/select-programme/'
              }}
              sx={{ marginTop: '16px' }}
            >
              Enrol on a programme
            </CustomButton>
          </Box>
        </div>
      )}

      {isSmallScreen ? null : <img src={SuccessImage} alt='book' />}
    </Wrapper>
  )
}

export default SuccessScreen
