import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'

import Layout from './components/Layout'
import ScrollToTop from './components/ScrollToTop'
import { routes } from './configs/routes'
import NotFoundProduct from './pages/NotFoundProduct'
import { abandonOrder, createOrder, deleteProductOrder, getOrder } from './store/enrolment/enrolment.actions'

import { useDispatch } from 'react-redux'

const tagManagerArgs = {
  gtmId: 'GTM-N4VC4DS9'
}

// TagManager.initialize(tagManagerArgs)

const App = () => {
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isLoadingInitData, setIsLoadingInitData] = useState(true)

  const localProductId = localStorage.getItem('productId')
  const localClassificationId = localStorage.getItem('classificationId')
  const localAgeId = localStorage.getItem('ageId')
  const localDateId = localStorage.getItem('dateId')

  useEffect(() => {
    if (window.location.pathname !== '/success') {
      const productId = params.get('productId')
      const classificationId = params.get('classificationId')
      const ageId = params.get('ageId')
      const dateId = params.get('dateId')

      const allParams = [
        { param: 'productId', value: productId },
        { param: 'classificationId', value: classificationId },
        { param: 'ageId', value: ageId },
        { param: 'dateId', value: dateId }
      ]
        .filter(({ value }) => value)
        .map(({ value, param }) => `${param}=${value}`)
        .join('&')

      if (
        (productId && productId !== localProductId) ||
        (dateId && dateId !== localDateId) ||
        (ageId && ageId !== localAgeId) ||
        (classificationId && classificationId !== localClassificationId)
      ) {
        dispatch(
          createOrder({
            productId,
            classificationId,
            ageId,
            dateId,
            callback: () => {
              setIsLoadingInitData(false)

              localStorage.setItem('productId', productId)
              localStorage.setItem('classificationId', classificationId)
              localStorage.setItem('ageId', ageId)
              localStorage.setItem('dateId', dateId)

              navigate(`/start-educational-pathway?${allParams}`)
            },
            callbackRejected: () => {
              setIsLoadingInitData(false)
              navigate('/not-found')
            }
          })
        )
      } else {
        const orderId = localStorage.getItem('orderId')

        if (orderId) {
          dispatch(
            getOrder({
              orderId,
              callback: () => setIsLoadingInitData(false),
              callbackRejected: () => {
                setIsLoadingInitData(false)
                localStorage.removeItem('orderId')
                navigate('/not-found')
              }
            })
          ).then(({ payload }) => {
            const productsLength = payload?.productOrders?.length

            if (productsLength > 1) {
              const lastProductOrder = payload?.productOrders[productsLength - 1]

              const isHasDateOrPreferred = lastProductOrder?.preferredMonths || lastProductOrder?.programDate

              if (!lastProductOrder?.price || !isHasDateOrPreferred) {
                dispatch(
                  deleteProductOrder({
                    productOrderId: lastProductOrder?.id
                  })
                )
              }
            }
          })
        } else {
          navigate('/not-found')
          setIsLoadingInitData(false)
        }
      }
    } else {
      setIsLoadingInitData(false)
    }
  }, [dispatch])

  useEffect(() => {
    const orderId = localStorage.getItem('orderId')
    if (orderId) {
      window.addEventListener('beforeunload', event => {
        // Recommended
        event.preventDefault()

        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true

        dispatch(abandonOrder({ orderId }))
      })
    } else {
      window.removeEventListener('beforeunload', event => {
        // Recommended
        event.preventDefault()

        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true
      })
    }
  }, [dispatch])

  if (isLoadingInitData) return null

  return (
    <div className='App'>
      <ScrollToTop />

      <Layout>
        <Routes>
          {routes.map(({ path, Component }) => (
            <Route key={path} path={path} element={Component} />
          ))}
          <Route
            path='*'
            element={
              <Navigate
                to={
                  !localClassificationId
                    ? `/start-educational-pathway?productId=${localProductId}&classificationId=${localClassificationId}`
                    : `/start-educational-pathway?productId=${localProductId}`
                }
                replace
              />
            }
          />
          <Route path='/not-found' element={<NotFoundProduct />} />
        </Routes>
      </Layout>
    </div>
  )
}

export default App
